/**
 * Sticky
 *
 * Gestion du comportement des éléments stickys au scroll
 */

$(document).ready(function () {


    let windowWidth = $(window).width(), // Largeur d'écran
        isMobile, // Flag indiquant si l'on est en mobile ou non
        isStickyisInitialized = false;
    /**
     * Sticky menu
     * @param sticky
     * @param stickyWrapper
     * @param scrollElement
     */

    function removeSticky(selector) {
        $(selector).each(function () {
            let sticky = $(this);
            let stickyWrapper = sticky.siblings('.sticky-wrapper');


            // Scroll & resize events
            $(window).off('scroll.sticky-onscroll resize.sticky-onscroll', function () {
                stickyToggle(sticky, stickyWrapper, $(this));
            });

            sticky.siblings('.sticky-wrapper').remove();
            sticky.removeClass('sticky');
        });
    }

    function resetMobileSticky() {
        isMobile = false;
        removeSticky('[data-trigger="sticky-mobile"]')
    }

    function resetDesktopSticky() {
        isMobile = true;
        removeSticky('[data-trigger="sticky-desktop"]')
    }

    function stickyForDesktop() {
        isStickyisInitialized = false;
        resetMobileSticky();
        initializeSitcky('[data-trigger="sticky-desktop"]');
    }

    function stickyForMobile() {
        isStickyisInitialized = false;
        resetDesktopSticky();
        initializeSitcky('[data-trigger="sticky-mobile"]');
    }

    // Custom function which toggles between sticky class (is-sticky)
    let stickyToggle = function (sticky, stickyWrapper, scrollElement) {
        let stickyHeight = sticky.outerHeight();
        let stickyTop = stickyWrapper.offset().top;
        if (scrollElement.scrollTop() > stickyTop) {
            stickyWrapper.height(stickyHeight);
            sticky.addClass("is-sticky");
        }
        else {
            sticky.removeClass("is-sticky");
            stickyWrapper.height('auto');
        }
    };

    function initializeSitcky(selector) {

        if (!isStickyisInitialized) {
            $(selector).each(function () {
                let sticky = $(this);
                let stickyWrapper = $('<div>').addClass('sticky-wrapper'); // insert hidden element to maintain actual top offset on page
                sticky.before(stickyWrapper);
                sticky.addClass('sticky');

                // Scroll & resize events
                $(window).off('scroll.sticky-onscroll resize.sticky-onscroll').on('scroll.sticky-onscroll resize.sticky-onscroll', function () {
                    stickyToggle(sticky, stickyWrapper, $(this));
                });

                // On page load
                stickyToggle(sticky, stickyWrapper, $(window));
            });

            isStickyisInitialized = true;
        }
    }



    /**
     * Initialise la navigation suivant la taille d'écran
     */
    function homeStickyInit() {
        if (breakpoints.isDesktop()) {
            if (isMobile !== false) {
                stickyForDesktop();
            }
        } else {
            if (isMobile !== true) {
                stickyForMobile();
            }
        }
    }

    homeStickyInit();
    $(window).resize(function(){
        homeStickyInit();
    });

});
